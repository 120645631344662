
import { defineComponent, ref } from 'vue'

import { aliPay, wxPay } from '../../service/pay/index'
import { IDataType } from '../../service/pay/type'

import message from '../../utils/message'

export default defineComponent({
  name: 'Pay',
  setup(props, content) {
    let radio = ref('支付宝')
    let num = ref(1)
    let payImg = ref('')
    async function getPay() {
      let data: IDataType
      if (radio.value === '支付宝') {
        data = await aliPay({ title: '赞助', totalAmount: num.value })
      } else {
        data = await wxPay({ title: '赞助', totalAmount: num.value })
      }
      if (data.success) {
        console.log(data)
        payImg.value = data.data.img
      } else {
        message.error(data.data)
      }
    }
    const payFun = () => {
      getPay()
    }
    return {
      radio,
      num,
      payFun,
      payImg,
    }
  },
})
